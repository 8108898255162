import React from 'react';
import tw from 'twin.macro'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import SEO from '../components/SEO/seo'
import SliceZone from '../components/SliceZone'

export default ({ data }) => {
  if (!data) return null;
  const doc = data.prismic.allOlive_oils.edges[0]
  const slices = doc.node.body
  return (
    <>
      <SEO title='Olive Oil' />
      <Root>
        <SliceZone slices={slices} />
      </Root>
    </>
  )
}

const Root = styled.div`
    ${tw`sm:mx-2 md:mx-12 mb-10`}
    min-height: calc(100vh - 230px);

    & h2 {
        ${tw`py-2`}
        font-size: 2rem;
        font-family: 'Futura Medium'
    }
`;

export const query = graphql`
query OliveOilQuery($lang: String) {
  prismic {
    allOlive_oils(lang: $lang) {
      edges {
        node {
            _meta {
                uid
                type
                lang
                alternateLanguages {
                    uid
                    type
                    lang
                }
            }
            body {
            ... on PRISMIC_Olive_oilBodySideimage_with_text {
              type
              label
              primary {
                content
                image1
              }
            }
            ... on PRISMIC_Olive_oilBodyImage_with_text {
              type
              label
              primary {
                image
                text
              }
            }
          }
        }
      }
    }
  }
}
`;